import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export default {
  en: {
    iso: 'en',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS,
    name: 'English',
  },
  jp: {
    iso: 'ja',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS_JP,
    name: 'Japanese',
  },
  es: {
    iso: 'es',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS,
    name: 'Spanish',
  },
  fr: {
    iso: 'fr',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS,
    name: 'French',
  },
  it: {
    iso: 'it',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS,
    name: 'Italian',
  },
  de: {
    iso: 'de',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS,
    name: 'German',
  },
  nl: {
    iso: 'nl',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS,
    name: 'Dutch',
  },
  pt: {
    iso: 'pt',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS,
    name: 'Portuguese',
  },
}
