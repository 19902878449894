import { titleCase } from 'lib/utils'
import messages from 'messages/seo'
import useSeo from 'hooks/useSeo'
import LOCALES from 'components/editorial/pages/feature/featureLocales'
import { useServerTime } from 'context/ServerTimeContext'
import useMetadataOverrides from 'hooks/useMetadataOverrides'

export const buildKeywords = (data) => {
  const keywords = []

  if (data.keywords && data.language) {
    keywords.push(...data.keywords.split(', '))
    keywords.push(titleCase(data.language))
  }

  if (
    data.locale &&
    Object.keys(LOCALES).includes(data.locale) &&
    data.series
  ) {
    keywords.push(titleCase(LOCALES[data.locale].name))
    keywords.push(data.series.title)
  }

  return keywords
}

const useBuildFeatureDetailSeo = ({ data }) => {
  const serverTime = useServerTime()
  const overrides = useMetadataOverrides()

  const titleMessage = {
    message:
      data.type === 'FILM'
        ? messages.featureDetailRAFilmsTitle
        : messages.featureDetailRAFeatureTitle,
    values: { title: data.title },
  }

  const images = []

  if (data.images) {
    const image = data.images.find((x) => x.type === 'STANDARD')

    if (image) {
      images.push({ url: image.imageUrl })
    }
  }

  const seoData = useSeo({
    titleMessage,
    overrides: {
      title: overrides?.title,
      description: overrides?.description ?? data.blurb,
      noindex: new Date(data.date).getTime() > serverTime,
      images,
    },
  })

  return seoData
}

export default useBuildFeatureDetailSeo
